// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
$font-family-base: "MuseoSansCyrl", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

.bg-gray-100 {
  background-color: #fbfbfb
}
